
window.language = {
	short: "en",
	full: "en-EN",
	tld: "en"
},
window.strings = {
  passenger:{
    one:"Passeggero",
    other:"Passeggeri"
  },
  place:{
    one:"Luogo",
    other:"Luoghi"
  },
  errors:{
    was_not_successful:"was not successful",
    without_a_valid_address:"without a valid address"
  },
  chart: {
    active:{
      one:"active",
      other:"active"},
    not_active:{
      one:"not active",
      other:"not active"}
  },
  DataTable: {
    url:"//cdn.datatables.net/plug-ins/1.10.25/i18n/English.json",
    print:'Print'}
}